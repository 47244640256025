@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"), url("./assets/font/Gotham-Book.otf");
}

body {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa !important;
  height: 100vh;
  margin: 0;
}

* {
  outline: 0px;
  outline-color: initial;
  outline-style: initial;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px #bbbbbe;
  border: solid 4px transparent;
  border-radius: 14px;
  min-height: 50px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
}