.rdrDayToday .rdrDayNumber span:after{
  background: rgb(99, 155, 72) !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, 
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, 
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, 
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: rgb(255, 255, 255) !important;
}

.rdrCalendarWrapper, .rdrDateRangeWrapper {
  width: 100%;
  min-width: 300px;
}

.rdrMonth {
  width: 100% !important;
}

.rdrDayDisabled {
  background: transparent !important;
  pointer-events: none;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #d5dce0 !important;
}
